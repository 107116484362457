<template>
  <form id="login-form" class="form" @submit.prevent="handleSubmit">
    <input type="hidden" name="_csrf" :value="csrf" />
    <input type="hidden" name="challenge" :value="challenge" />
    <div class="log_main">
      <div class="pass_title">
        <p>首次登录密码验证</p>
      </div>
      <div class="log_ui logTips verify_pwd_tips">
        <p>帐号({{ account }})的手机号码未绑定，需要先登录</p>
      </div>
      <div class="log_ui logTool" style="display: none">
        <div class="dsib log_tool_outer vm">
          <input
            class="txt username vm"
            type="text"
            name="account_name"
            placeholder="输入帐号"
            :value="account"
            readonly="readonly"
          />
        </div>
      </div>
      <div class="log_ui logPass">
        <div class="dsib log_tool_outer vm">
          <input
            class="txt userPass"
            type="password"
            name="password"
            placeholder="输入密码"
            required
            @input="updateStore('password', $event.target.value)"
          />
        </div>
      </div>
      <div class="append_op dsib">
        <div class="dsib autoLogSet">{{ errmsg }}</div>
      </div>
      <div class="logBtnOuter verify_pass_login">
        <BtnWithLoading class="btn" type="submit" :loading="isPending"
          >登录</BtnWithLoading
        >
      </div>
    </div>
  </form>
</template>
<script>
import mapState from '@/mixins/map-state'
import updateStore from '@/mixins/update-store'
import handleErr from '@/mixins/handle-err'

export default {
  mixins: [mapState, updateStore, handleErr],
  data() {
    return {
      isPending: false
    }
  },
  mounted() {
    if (!this.account) {
      this.$router.replace('/login')
    }
  },
  methods: {
    handleSubmit(e) {
      console.log('submit', e)
      let payload = {
        _csrf: this.csrf,
        challenge: this.challenge,
        LoginType: this.loginType
      }

      if (this.captcha.id) {
        payload.captchaId = this.captcha.id
        payload.captchaValue = this.captchaValue
      }

      payload['account_name'] =
        this.account && this.encrypter.encrypt(this.account)
      payload['password'] =
        this.password && this.encrypter.encrypt(this.password)

      sessionStorage.setItem('current_pwd', payload['password'])

      this.isPending = true
      this.Error = ''
      this.$api
        .verifypwd(payload)
        .then(res => {
          this.isPending = false
          console.log(res)
          res.data = res.data || {}
          if (res.data.errcode !== '0' && res.data.errmsg) {
            this.updateStore('errmsg', res.data.errmsg)
          }
        })
        .catch(err => {
          this.handleErr(err)
        })
    }
  }
}
</script>
